import { sumBy } from 'lodash';
import moment, { Moment } from 'moment';
import { PlotMouseEvent } from 'plotly.js';
import { Plot, mainConfig } from '../../../../../common/charts';
import { chartUtils } from '../../../../../../utils';
import { arrayUtils } from '../../../../../../utils/array.utils';
import { ChartView } from '../../../../../../types/dashboard/AmrChart';
import { SessionAccess, SessionMeta } from '../../../../../../types/amr-pipeline/models/AnalyticsSession';
import { TransactionClientActivity } from '../../../../../../types/amr-pipeline/models/TransactionSession';
import { clientsActivityChart } from '../../../../../../constants/amr-pipeline/clientsActivityChart';
import { TransactionAccessType, TransactionAccessTypes } from '../../../../../../types/amr-pipeline/enums/TransactionAccessType';
import { constants } from '../../../../../../constants';
import { DashboardNoSelectedFilters } from '../../../../../dashboard/DashboardNoSelectedFilters';
import { TransactionStatus } from '../../../../../../types/amr-pipeline/enums/TransactionStatus';
import { amrChartUtils, GroupedBy, Range } from '../../../../../../utils/amr-chart.utils';
import { TransactionAnalyticsFilter } from '../../../../../../types/analytics/AnalyticsFilter';

type BarClickEventHandler = (accessType: TransactionAccessType, date: Date) => void;

interface ClientsActivityChartProps {
    divId?: string;
    filter: TransactionAnalyticsFilter;
    data: TransactionClientActivity[];
    closingDate?: Date;
    status: TransactionStatus;
    onBarClick: BarClickEventHandler;
}

const ChartRangeFormat = 'YYYY-MM-DD';
const DefaultRangeDays = 10;

export function ClientsActivityChart({ divId, data, filter, closingDate, status, onBarClick }: ClientsActivityChartProps) {
    const closingMoment = closingDate && status === TransactionStatus.Closed ? moment(closingDate) : undefined;
    const chartEndMoment = closingMoment?.isBefore(moment(), 'day') ? closingMoment : moment();

    const {
        chartLayout,
        tickColor,
        tickFont,
        barColors,
        zeroLineColor,
        hoverlabel,
    } = clientsActivityChart;

    const handleBarClick = (e: PlotMouseEvent) => {
        const point = e && e.points && e.points[0];

        if (point && point.data) {
            const date = moment(point.x).toDate();
            const [accessType] = point.data.customdata;

            onBarClick(accessType as TransactionAccessType, date);
        }
    };

    const getEdgeDimensionValues = (clientsActivity: SessionMeta[]): Range<Moment> => {
        if (filter.date.dateFrom && filter.date.dateTo) {
            const min = moment(filter.date.dateFrom);
            const max = moment(filter.date.dateTo);

            // If filter is selected for one day, chart will render shitty
            // thin bars. Extend to two days to help it render correctly.
            if (moment.duration(max.diff(min)).days() < 2) {
                return {
                    min: min.subtract(1, 'day'),
                    max,
                }
            }

            return { min, max };
        }

        const minDateRow = arrayUtils.min(clientsActivity, x => x.accessDateTime)!;
        const tenDaysAgo = chartEndMoment.clone()
            .subtract(DefaultRangeDays, 'days')
            .startOf('day');

        const min = tenDaysAgo.isBefore(minDateRow?.accessDateTime) ? tenDaysAgo : moment.utc(minDateRow?.accessDateTime);

        return {
            min,
            max: chartEndMoment,
        };
    };

    const getChartRange = () => {
        // Pick either dates from filter, or current date as
        // reference. Manipulations with start/end of days and 12 hours
        // are to align ticks of chart and now show an edge of previous/next
        // days
        const fromMoment = filter.date.dateFrom
            ? moment(filter.date.dateFrom)
                .startOf('day')
                .subtract(12, 'hours')
            : chartEndMoment
                .clone()
                .subtract(DefaultRangeDays, 'days')
                .startOf('day')
                .add(12, 'hours');

        const toMoment = filter.date.dateTo
            ? moment(filter.date.dateTo)
                .endOf('day')
                .subtract(12, 'hours')
            : chartEndMoment
                .clone()
                .endOf('day')
                .subtract(12, 'hours');

        return [
            fromMoment.format(constants.dateTimeFormatUtc),
            toMoment.format(constants.dateTimeFormatUtc)
        ];
    };

    const getSliderRange = ({ min, max }: Range<Moment>) => {
        if (!min || !max) {
            return [];
        }

        // Set same range for slider as for chart,
        // only if date range is less or equal than 10 days
        return moment.duration(max.diff(min)).asDays() > DefaultRangeDays
            ? []
            : getChartRange();
    };

    const getHoverInfoText = (clientsActivityMap: Map<number | undefined, TransactionClientActivity[]>) => {
        return Array.from(clientsActivityMap.entries())
            .sort(([versionA], [versionB]) => (versionB || 0) - (versionA || 0))
            .reduce((acc, versionClientsActivity) => {
                const [version, clientsActivity] = versionClientsActivity;
                const [firstEntry] = clientsActivity;

                const accessTypeTitle = TransactionAccessTypes.find(t => t.value === firstEntry.accessType)?.text;
                const versionString = `V.${version} published on ${moment(firstEntry.versionDate).format(constants.dateTimeFormat)} <br />` +
                    `${accessTypeTitle}: ${sumBy(clientsActivity, x => x.numberOfAccess)} view(s)`;

                if (acc) {
                    return `${acc} <br />${versionString}`;
                }

                return versionString;
            }, '');
    };

    const getChartData = ({ min, max } : Range<Moment>) => {
        const map = arrayUtils.groupBy(data, (row: SessionAccess<TransactionAccessType>) => row.accessType);

        return TransactionAccessTypes.map((accessType, index) => {
            const data: TransactionClientActivity[] = map.get(accessType.value) || [];

            const isSameDay = (row: SessionMeta) => (iteratee: GroupedBy<Moment, SessionMeta>) => iteratee.date.isSame(row.accessDateTime, 'day');

            const groupedByDate = data.reduce((acc: GroupedBy<Moment, TransactionClientActivity>[], row: TransactionClientActivity) => {
                const existingEntry = acc.find(isSameDay(row));

                if (existingEntry) {
                    return [
                        ...acc.filter(x => !isSameDay(row)(x)),
                        {
                            ...existingEntry,
                            entries: [
                                ...existingEntry.entries,
                                row,
                            ]
                        }
                    ];
                }

                return [
                    ...acc,
                    { date: moment(row.accessDateTime), entries: [row] }
                ];
            }, []);

            // Fill in gaps in dates with blank items, to preserve integrity of chart data
            const fillGaps = amrChartUtils.gapsFiller(groupedByDate, row => row.date, date => ({ date, entries: [] }));

            const dataWithFilledGaps = (min && max) ? fillGaps({ min, max }, 'day') : groupedByDate;

            return dataWithFilledGaps.reduce((acc: any, { date, entries }: { date: Moment, entries: TransactionClientActivity[] }) => {
                const { x, y, hovertext } = acc;

                const groupedByVersions = arrayUtils.groupBy(entries, (row: TransactionClientActivity) => row.version);

                return {
                    ...acc,
                    hovertext: [...hovertext, getHoverInfoText(groupedByVersions)],
                    x: [...x, date.format(ChartRangeFormat)],
                    y: [...y, sumBy(entries, r => r.numberOfAccess)],
                };
            }, {
                x: [],
                y: [],
                hovertext: [],
                type: 'bar',
                hoverinfo: 'text',
                customdata: [accessType.value],
                marker: {
                    color: barColors[index],
                },
            });
        })
    };

    const getLayout = (dateRange: Range<Moment>) => {
        const xAxisRange = getChartRange();
        const rangeSliderRange = getSliderRange(dateRange);

        return {
            ...chartLayout,
            showlegend: false,
            autosize: true,
            hovermode: 'closest',
            hoverlabel,
            barmode: ChartView.Grouped,
            xaxis: {
                tickcolor: tickColor,
                type: 'date',
                tickfont: tickFont,
                tickformat: '%m/%d/%y',
                fixedrange: true,
                dtick: constants.dayMs,
                range: xAxisRange,
                rangeslider: {
                    range: rangeSliderRange,
                    bgcolor: 'rgba(79, 123, 156, 0.2)',
                },
            },
            yaxis: {
                showgrid: true,
                showtickprefix: 'all',
                fixedrange: true,
                gridcolor: tickColor,
                ticks: 'inside',
                tickcolor: tickColor,
                tickfont: tickFont,
                zerolinecolor: zeroLineColor,
            },
        }
    };

    const renderChart = () => {
        const edgeDimensions = getEdgeDimensionValues(data);
        const chartData = getChartData(edgeDimensions);
        const layout = getLayout(edgeDimensions);

        return (
            <>
                <Plot
                    onHover={chartUtils.setCursor('pointer')}
                    onUnhover={chartUtils.setCursor('crosshair')}
                    divId={divId}
                    data={chartData}
                    layout={layout}
                    config={mainConfig}
                    onClick={handleBarClick}
                />
                <div className="agenda">
                    <div className="agenda-item overview">Overview</div>
                    <div className="agenda-item structure">Structure</div>
                    <div className="agenda-item target-portfolio2">Target Portfolio</div>
                    <div className="agenda-item comparative-metrics">Comparative Metrics</div>
                    <div className="agenda-item documents">Documents</div>
                    <div className="agenda-item iois">IOIs</div>
                </div>
            </>
        );
    };

    return data.length ? renderChart() : <DashboardNoSelectedFilters />;
}

ClientsActivityChart.defaultProps = {
    divId: 'client-activity-div-id'
};
