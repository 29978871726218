import { Fragment } from 'react'
import moment from "moment";
import { values } from "lodash";
import { OriginatingTransaction } from "../../../../../types/amr-pipeline/models/OriginatingTransaction";
import { IssuanceSpreadBase, Spread } from "../../../../../types/dashboard/IssuanceSpread";
import { Currency } from "../../../../../types/enums/Currency";
import { isRequestSuccess } from "../../../../../utils";
import { EmptyPlaceholder, Preloader } from "../../../../common";
import { SectionContent } from "../../../common/section/SectionContent";
import { TabBody } from "../../../common/tabs/TabBody";
import { ComparableDealTable } from "./ComparableDealTable";
import { ComparativeMetricsWidget } from "./ComparativeMetricsWidget";
import { useDealComparativeMetrics } from "./hooks/useDealComparativeMetrics";
import { constants } from "../../../../../constants";
import { collateralTypesAbbr } from "../../../../../constants/collateral-types";
import { TransactionTypes } from '../../../../../types/amr-pipeline/enums/TransactionType';

interface Props {
    transaction: OriginatingTransaction
    isLoading: boolean
}

const STATS_DAYS = 30;

export function isSpreadEmpty(spread?: Spread) {
    return spread == null || !values(spread).some(v => v)
}

export function ComparativeMetrics({ transaction, isLoading }: Props) {
    const { requestState, metrics } =
        useDealComparativeMetrics(transaction.dealReferenceName, transaction.referenceName);

    const createSpreadFromSingleValue = (value?: number): Spread | undefined => value ? {
        min: value,
        q1: value,
        median: value,
        q3: value,
        max: value,
        count: 1,
        mean: value
    } : undefined

    const comparableSpread: IssuanceSpreadBase = {
        was: metrics?.statsWAS,
        wal: metrics?.statsWAL,
        warf: metrics?.statsWARF,
        ds: metrics?.statsDS
    }

    const dealSpread: IssuanceSpreadBase = {
        was: createSpreadFromSingleValue(metrics?.currentWAS),
        wal: createSpreadFromSingleValue(metrics?.currentWAL),
        warf: createSpreadFromSingleValue(metrics?.currentWARF),
        ds: createSpreadFromSingleValue(metrics?.currentDS)
    }

    const getCurrency = () => {
        if (transaction.currency?.code === Currency.USD) return 'US';
        if (transaction.currency?.code === Currency.EUR) return 'EU';
        return '';
    }

    const getTerm = () => {
        if (metrics?.isLong == null) return ''
        return metrics?.isLong ? 'long-term' : 'short-term '
    }

    const getTransactionType = () =>
        TransactionTypes.find(x => x.value === transaction.type)?.text

    const renderDescription = () => [
        'Comparison of',
        <b key="1">{metrics?.count.toString()}</b>,
        <Fragment key="2">{getCurrency() ? <b>{getCurrency()}</b> : null}</Fragment>,
        <b key="3">{collateralTypesAbbr[transaction.collateralType]}</b>,
        <Fragment key="4">{getTerm() ? <b>{getTerm()}</b> : null}</Fragment>,
        "(Active & Priced)",
        <b key="6">{getTransactionType()}</b>,
        'deals listed on the Issuance Monitor over the past',
        STATS_DAYS,
        'days from',
        moment(metrics?.date).format(constants.dateFormat) + ",",
        "with data accurate as of the publishing date."
    ]
        .filter(x => x)
        .map(x => [x, ' '])
        .flat();

    const hasData =
        isRequestSuccess(requestState) &&
        metrics &&
        metrics.count && (
            (metrics.currentDS && !isSpreadEmpty(metrics.statsDS)) ||
            (metrics.currentWAL && !isSpreadEmpty(metrics.statsWAL)) ||
            (metrics.currentWARF && !isSpreadEmpty(metrics.statsWARF)) ||
            (metrics.currentWAS && !isSpreadEmpty(metrics.statsWAS))
        );

    const renderContent = () =>
        <>
            <p className="comparative-metrics-info">{renderDescription()}</p>
            <SectionContent title="Comparative Metrics">
                <div className="metrics-chart-row">
                    <div className="metrics-chart-col">
                        <ComparativeMetricsWidget
                            title="WAS *"
                            description="* Weighted Average Spread"
                            divId="us-bsl-new-issue-spreads-was-chart"
                            field="was"
                            requestState={requestState}
                            comparableSpread={comparableSpread}
                            dealSpread={dealSpread}
                            chartNameLimitedPlan="WAS"
                        />
                    </div>
                    <div className="metrics-chart-col">
                        <ComparativeMetricsWidget
                            title="WAL *"
                            description="* Weighted Average Life"
                            divId="us-bsl-new-issue-spreads-wal-chart"
                            field="wal"
                            requestState={requestState}
                            comparableSpread={comparableSpread}
                            dealSpread={dealSpread}
                            chartNameLimitedPlan="WAL"
                        />
                    </div>
                    <div className="metrics-chart-col">
                        <ComparativeMetricsWidget
                            title="WARF *"
                            description="* Weighted Average Rating Factor"
                            divId="us-bsl-new-issue-spreads-warf-chart"
                            field="warf"
                            requestState={requestState}
                            comparableSpread={comparableSpread}
                            dealSpread={dealSpread}
                            chartNameLimitedPlan="WARF"
                        />
                    </div>
                    <div className="metrics-chart-col">
                        <ComparativeMetricsWidget
                            title="DS *"
                            description="* Diversity Score"
                            divId="us-bsl-new-issue-spreads-ds-chart"
                            field="ds"
                            requestState={requestState}
                            comparableSpread={comparableSpread}
                            dealSpread={dealSpread}
                            chartNameLimitedPlan="DS"
                        />
                    </div>
                </div>
            </SectionContent>
            {
                metrics?.comparableDeals.length &&
                <SectionContent title="Reference Transactions" className="">
                    <ComparableDealTable deals={metrics.comparableDeals} />
                </SectionContent>
            }
        </>

    return (
        <Preloader inProgress={isLoading || !isRequestSuccess(requestState)}>
            <TabBody className="tabs-data-padding component-comparable-metrics">
                {hasData ? renderContent() : <EmptyPlaceholder text="No information to display." />}
            </TabBody>
        </Preloader>
    )
}
